import Image from "next/image";
import Link from "next/link";
import React from "react";
import Remote, { Blur } from "../LoaderRemote";
import ProductDestinationsContainer from "../ProductDestinations/ProductDestinationsContainer";

const ProductDestinations = ({ data }) => {
  return (
    <ProductDestinationsContainer>
      {data &&
        data.map((e, index) => {
          return (
            <div key={index} className="flex-33 p-2">
              <Link href={e.url}>
                <a title={e.title}>
                  <div className="text-center relative h-20 rounded-md overflow-hidden ">
                    <Image
                      src={e.resim}
                      layout="fill"
                      loader={Remote}
                      objectFit={"cover"}
                      objectPosition={"center"}
                      alt={e.title}
                      sizes="(max-width: 768px) 25vw,"
                      placeholder="blur"
                      blurDataURL={Blur(e.resim)}
                      priority={index == 0}
                    />
                    <span className="text-white absolute bottom-2 text-lg font-semibold left-1/2 -translate-x-1/2 z-10">
                      {e.baslik}
                    </span>
                    <span className="absolute inset-0 bg-gradient-to-t from-[#323232] to-transparent"></span>
                  </div>
                </a>
              </Link>
            </div>
          );
        })}
    </ProductDestinationsContainer>
  );
};

export default ProductDestinations;
